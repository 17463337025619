/* New colors */
/* Primary */
$primary-blue: #007CCB;
$blue-hover: #1d527f;
$secondary-blue: #BEE7F8;
$white: #FFFFFF;
$white-translucid: #ffffffc4;
$background: #F8FAFB;
$text-color: #32607D;
$button-gradiant: transparent linear-gradient(225deg, $primary-blue 0%, #36A0EA 100%) 0% 0% no-repeat padding-box;

/* Secondary */
$blue-light: #E3F7FF;
$blue-extra-light: #F1FBFF;
$gray-light: #E6EBEE;
$gray: #BECAD2;
$dark-gray: #7E9AAC;
$dark: #203F54;

/* Info colors */
$blue: $primary-blue;
$red: #FF5364;
$red-light: #fdedee;
$orange: #FFAA84;
$orange-light: #FFF6F2;
$yellow: #FFCE48;
$yellow-light: #FDF9ED;
$green: #5CE882;
$green-light: #EEFDF2;

/* Related to elements */
$--box-shadow: 0px 4px 10px #7E9AAC29;
$--border-radius: 15px;

/*bootstrap override*/
$font-size-base-sm: 0.875rem !default;
$font-size-base: 1rem !default;
$h2-font-size: $font-size-base * 1.75;
$h750-font-size: $font-size-base * 2;
$h700-font-size: $font-size-base * 1.5;
$h600-font-size: $font-size-base * 1.25;
$h600-line-height: $font-size-base * 1.75;
$h500-font-size: $font-size-base;
$h500-line-height: $font-size-base * 1.5;
$h400-font-size: $font-size-base;
$h400-line-height: $font-size-base * 1.5;
$font-size-xxl: $font-size-base * 5;

$font-weight-normal: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-bold: $font-weight-500;
$font-weight-bolder: $font-weight-bold;


/*bootstrap override*/

$input-border-color: $gray;

$table-active-bg-factor: .1 !default;

$font-family-sans-serif: "IntrumSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;

$input-bg: $white;

$body-bg: $background;
$body-color: $dark;

$accordion-bg: $white;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $body-color;
$accordion-button-focus-border-color: $white;
$accordion-button-focus-box-shadow: $white;
$accordion-border-radius: 0;

$badge-font-size: 1em;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: .5em;

//$link-color: #aba8a6;

$card-border-radius: 0;

$breadcrumb-bg: inherit;
$breadcrumb-divider-color: $dark-gray;
$breadcrumb-active-color: $text-color;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 8px;
$breadcrumb-margin-bottom: 0;

$table-group-separator-color: none;
$table-active-bg: $gray;
$table-cell-padding-x-sm: 0.75rem;


$link-decoration: none;

$grid-gutter-widths: (
        xs: 24px,
        md: 16px,
        lg: 24px,
        xl: 24px
);

$container-max-widths: (
        xxl: 1500px
);

/* mobile */
$nav-bar-bottom-height: 64px;
