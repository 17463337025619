@import "fonts.css";
@import "variables";
@import "functions";
@import "mixins";
@import "keyframes";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "spinner";
@import "./bootstrap-override.scss";
@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: calc($grid-gutter-width / 2);
    .container, [class*='container-'] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
    .row:not(.no-gutters) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: 14px !important;
      padding-left: 14px !important;

      &.col-main {
        background: set-background-color($grid-breakpoint, $white);
        border-top: if($grid-breakpoint=='xs', $border-width solid $border-color !important, 0 !important);
        border-bottom: if($grid-breakpoint=='xs', $border-width solid $border-color !important, 0 !important);
        padding-top: if($grid-breakpoint=='xs', $grid-gutter-half-width, 0);
        padding-bottom: if($grid-breakpoint=='xs', $grid-gutter-half-width, 0);
      }

      .card {
        border-color: if($grid-breakpoint=='xs', rgba($dark, .0), $card-border-color);
        margin-bottom: if($grid-breakpoint=='xs', 0, 1rem);

        .card-body {
          padding: if($grid-breakpoint=='xs', 0, $card-spacer-x)
        }
      }
    }
    .offcanvas-end {
      width: if($grid-breakpoint=='xs', 75%, $offcanvas-horizontal-width)
    }

  }
}

.create-case-page {
  input, select {
    background-color: $background !important;
  }
}

.invoice-entry .form-control:invalid, .form-control.is-invalid {
  padding: 0.375rem 0.75rem
}

.invoice-entry .invalid-duplicate {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}


.btn-primary.btn-link {
  text-decoration: none
}

.btn-primary {
  background-color: $blue;
}

.btn-danger {
  color: $white;
}

.btn-check {
  pointer-events: auto !important;
}

.toggle-button-group {
  .btn-check:checked + .btn-secondary {
    color: #6C757D;
    border-color: #ced4da;
  }
}

body {
  > * {
    font-family: $font-family-sans-serif;
  }

  background-color: #F8FAFB !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: 1rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  &.nav-item {
    margin-bottom: 0;
  }
}

.backdrop {
  z-index: 100;
}

#popover-alert {
  color: $text-color !important;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 0 1px 7px 2px rgba(73, 80, 87, 0.1);
  background-color: $blue-light;
  border: none;
  border-color: $blue-light !important;
  max-width: 22rem !important;

  .popover-arrow::before, .popover-arrow::after {
    border-bottom-color: $blue-light !important;
  }
}

.popover-alert-body {
  color: #215682 !important;
}

.form-check-input:checked {
  background-color: $blue !important;
  border-color: $text-color !important;
}

.dark-overlay {
  z-index: 1045;
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: default;
  }
}

.gray-400 {
  color: $gray
}

.gray-500 {
  color: $gray
}

.gray-600 {
  color: $dark-gray
}

.gray-700 {
  color: $dark-gray
}


.h400 {
  font-size: $h400-font-size;
  font-weight: normal !important;
  line-height: $h400-line-height;
}

.h500 {
  font-size: $h500-font-size;
  font-weight: $font-weight-500;
  line-height: $h500-line-height;
}


.h600 {
  font-size: $h600-font-size;
  font-weight: $font-weight-500;
}

.h700 {
  font-size: $h700-font-size !important;
  font-weight: 500;
}

.h750 {
  font-size: 2rem;
  font-weight: 500;
}

.h800 {
  font-size: 2.5rem;
  font-weight: 500;
}

.h1100 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 4rem;
}

.bold-700 {
  font-weight: 700;
}

.grey, .gray {
  color: $gray;
}

.light-gray {
  color: $gray-light
}

.dark-gray {
  color: $dark-gray
}

.blue {
  color: $primary-blue;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.orange {
  color: $orange
}

.red {
  color: $red !important;
}

.hover-red:hover {
  color: $red;
  transition-duration: .1s;
}

.invalid-field {
  svg {
    display: none;
  }

  span {
    display: inline-block !important;
  }
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background: none;
}

.form-control:disabled {
  background-color: $background;
}

.form-group:not(:first-child) {
  margin-top: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group {
  .form-label {
    color: $text-color;
  }

  .form-label.disabled {
    color: $dark-gray;
  }

  input {
    border: solid 1px $gray-light;
    border-radius: 8px;
  }

  input:focus {
    box-shadow: none;
  }

  input:disabled {
    background-color: $background;
    color: $dark-gray;
  }
}

.form-label {
  margin-bottom: .5rem;
  margin-left: .25rem;
  line-height: 1rem;
}

.form-button-group {
  margin-top: 1.5rem;
  text-align: center;

  button:not(:first-child) {
    margin-left: 1.5rem;
  }
}

button:focus {
  box-shadow: none !important;
}

.toggle-group-active {
  background-color: $background !important;
}

.toggle-group-active.btn.btn-secondary:focus {
  color: #6C757D !important;
  background-color: $background !important;
}

.toggle-group-active.btn.btn-secondary:active {
  color: #6C757D !important;
  background-color: $background !important;
}

.breadcrumb {
  padding: 12px 0 8px 8px !important;
}

.breadcrumb-item {
  .active {
    color: $dark-gray !important;
    font-weight: 500 !important;
  }
}

.date-last-import div {
  display: inline-block
}

.link-light {
  a {
    color: $dark-gray !important;

    &:hover {
      color: $dark-gray !important;
      font-weight: normal;
      text-decoration: underline;
    }
  }
}

a.link-no-style {
  &:hover {
    text-decoration: none;
    color: unset;
  }
}

.bg-intrum-primary {
  background: $white;
}


.bg-intrum-gray-100 {
  background-color: $body-bg !important;
}

.text-secondary {
  color: $dark !important
}

.text-gray-600 {
  color: $dark-gray !important
}

.text-gray-800 {
  color: $dark !important
}

.text-secondary-light {
  color: $dark-gray !important
}

.text-primary {
  color: $text-color !important;
}

.text-dark {
  color: $dark !important;
}

.text-black {
  color: $dark;
}

.text-title, .link-title {
  color: $blue;
}

.timeline-title {
  color: $text-color;
  font-size: 1rem;
}

.rubine {
  color: rgb(189, 0, 82);
}

.white {
  color: $white;
}

.font-weight-500 {
  font-weight: $font-weight-500
}

.font-weight-600 {
  font-weight: $font-weight-600
}

.font-weight-700 {
  font-weight: $font-weight-700
}

.font-size-base {
  font-size: $font-size-base;
}

.font-size-xxl {
  font-size: $font-size-xxl;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-sm {
  font-size: $font-size-base-sm;
}

.line-heigt-normal, .line-height-normal {
  line-height: normal;
}

.line-height-sm {
  line-height: 1.15;
}

.border-right-dashed-cyan {
  border-width: 1px;
  border-right-style: dashed;
  border-color: $blue;
}

.follow-up-history {
  list-style-type: none;
  padding-left: 1rem !important;

  li {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.35rem;
    line-height: 1rem;

    .border-solid-blue {
      height: 1rem;
      margin-bottom: 0.35rem;
    }

    span {
      line-height: 0.85rem;
      display: block;
    }

    small {
      line-height: 0.65rem;
      color: $dark-gray;
      margin-left: 1rem;
    }
  }

  .comment {
    font-size: 0.875rem;
    margin-top: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.35rem;
    color: #6C757D;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    span {
      margin-left: 0.75rem;
      display: inline-block;
      line-height: 1rem;
    }

    .border-solid-blue {
      margin-bottom: 0;
      height: auto;
    }
  }
}

.border-solid-blue {
  border-width: 1px;
  border-left-style: solid;
  border-color: $blue;
}

.border-right-cyan {
  border-width: 1px;
  border-right-style: solid;
  border-color: $blue;
  height: 100%;
}

.border-right-gray {
  border-width: 2px;
  border-right-style: solid;
  border-color: $dark-gray;
  height: 100%;
}

.border-right-dashed-gray {
  border-width: 1px;
  border-right-style: dashed;
  border-color: $dark-gray;
}

.border-right-secondary {
  border-width: 1px;
  border-right-style: solid;
  border-color: $dark-gray;
}

.text-intrum-cyan {
  color: $primary-blue;
}

.text-intrum-blue {
  color: $primary-blue;
}

.text-dark-gray {
  color: $dark-gray;
}

.nav-tabs {
  border-bottom: none !important;
}

.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid-size-text-count {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert {
  color: $text-color !important;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  padding: 13px 1rem;

  &.alert-primary, &.alert-info {
    background-color: $blue-light !important;
    border-left: solid $primary-blue 5px;
  }

  &.alert-success {
    background-color: $green-light !important;
    border-left: solid $green 5px;
  }

  &.alert-warning {
    background-color: $yellow-light !important;
    border-left: solid $yellow 5px;
  }

  &.alert-warn-high {
    background-color: $orange-light !important;
    border-left: solid $orange 5px;
  }

  &.alert-error, &.alert-danger {
    background-color: $red-light !important;
    border-left: solid $red 5px;
  }
}

.shadow-nav {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%)
}

.grid-invoices {
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  thead tr th {
    position: sticky;
    top: 0 !important;
  }
}

.container-invoices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 200px;
  overflow-y: scroll;

}

section:not(:first-child) {
  margin-top: 1.5rem
}

.is-demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: $white;
  padding: 0;
  background: linear-gradient(0.25turn, #55c0ef, #007ccb, #007ccb);
}

.nextDebitDate {

  .text-nowrap {
    display: contents
  }

}

.link-button {
  background: none;
  border: none;
  color: $dark-gray !important;
  font-weight: 500 !important;
  height: 2rem !important;

  &:hover {
    background: none !important;
    border: none;
    color: $dark-gray !important;
    text-decoration: underline;
  }
}

.btn {
  box-shadow: 0 0 0 #BECAD2;
  border-radius: $--border-radius;

  &:not(.btn-link) {
    padding: 0 1rem;
    height: 40px;
    font-size: 1rem;
    font-weight: 500;
  }

  &.btn-secondary {
    border: 2px solid $text-color;
    color: $text-color !important;
    background-color: $white;
  }

  &.btn-secondary:hover {
    background-color: $text-color !important;
    border-color: $text-color;
    color: $background !important
  }

  &.btn-secondary:active {
    color: $white !important;
    background-color: yellow;
  }

  &.btn-secondary:active:hover {
    color: $white !important;
    background-color: pink;
  }

  &.btn-secondary:focus {
    color: $white !important;
    background-color: $text-color !important;
  }

  &.btn-primary {
    color: $white;
  }

  &.btn-outline-alert {
    color: $orange;
    border: solid 2px $orange;
    background-color: $white;
    &:hover {
      background-color: $orange;
      border: solid 2px $orange !important;
      color: $white !important;
    }
  }
  &.btn-alert {
    color: $white;
    border: solid 2px $orange;
    background-color: $orange;
    &:hover {
      background-color: darken($orange, 10%);
      border: solid 2px darken($orange, 10%) !important;
      color: $white !important;
    }
  }

  &.btn-primary:hover {
    background-color: $blue-hover;
    border-color: $blue-hover;
    color: $background;
    text-decoration: none;
  }

  &.btn-primary:focus {
    background-color: $blue-hover;
    border: none;
    box-shadow: 0 0 0 #BECAD2;
    border-radius: $--border-radius;
  }

  &.button-link {
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    padding: 0 !important;
  }
}

.btn-group > .btn-secondary {
  border: solid 1px $input-border-color !important;
  color: $dark-gray !important;

}

.bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
  color: $dark-gray;
}

.info-text {
  display: flex;
  gap: .5rem;
  align-items: center;
}

.info-indicator-container {
  display: flex;

  .info-indicator-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .info-indicator-title {
    font-size: $font-size-lg;
    font-weight: 500;
  }

  .info-indicator-value {
    font-size: 2rem;
    font-weight: 500;
  }

  .petrol {
    color: #00a0a6;
  }

  .red {
    color: $red
  }

  .dark-blue {
    color: $blue
  }

  .grey, .gray {
    color: $gray;
  }
}

.form-accordion {
  .accordion-item {
    border: none;
  }

  .accordion-header {
    button {
      font-weight: 500;
      font-size: 1.15rem;
    }

    button[aria-expanded="false"] {
      border: solid 1px $background;
      border-radius: 4px;
    }

    button[aria-expanded="true"] {
      background-color: $background;
      color: $blue;
      border-radius: 4px;
    }
  }
}

.sliced-text {
  cursor: pointer;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  &:hover {
    text-decoration: underline;
  }
}

.double-input {
  display: flex;

  .double-input-left {
    width: 100%;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .double-input-right {
    width: 100%;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}

.solvency-info-card {
  .section-title {
    font-weight: 500;
    font-size: 1.1rem;
    padding: .5rem 1rem;
    background-color: $background;
    border-radius: 4px;
    margin: 1rem 0;
  }

  .solvency-row {
    margin: .3rem 1rem !important;

    .solvency-col-title {
    }

    .date {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .bold {
      font-weight: 500;
    }
  }

  hr {
    display: block;
    height: 2px;
    border: 0;
    border-top: 1px solid $background;
    margin: 1em 0;
    padding: 0;
  }
}

.menu-switch-tab {
  .switch-tab {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem .75rem;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .switch-tab:not(.risky) {
    color: $dark-gray;
    .tab-icon {
      color: $dark-gray
    }
  }
  .active:not(.risky) {
    color: $blue;
    background-color: $blue-extra-light;
  }
  .active.risky {
    color: $orange;
    background-color: $orange-light;
  }
  .switch-tab.risky {
    color: $orange;
  }
}

.btn-arrow {
  color: $blue;

  &:hover {
    color: $blue-hover;
  }

  &:focus {
    color: $blue-hover;
  }
}

.btn-chevron {
  color: $dark-gray;
  cursor: pointer;

  &:hover {
    color: $dark-gray;
  }

  &:focus {
    color: $dark-gray;
  }
}

.btn-chevron[aria-disabled="true"] {
  color: $gray;
}

a {
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    color: $blue;
  }

  &:active {
    color: $dark-gray;
  }

  &:focus {
    color: $dark-gray;
  }
}

.button-link-standard {
  color: $dark-gray;
  font-weight: normal !important;

  &:hover {
    color: $dark-gray;
    font-weight: 500;
  }

  &:active {
    color: $dark-gray;
    font-weight: 500;
  }

  &:focus {
    color: $dark-gray;
    font-weight: 500;
  }
}

.link-standard {
  color: $dark-gray;
  font-weight: 500;

  &:hover {
    color: $dark;
    text-decoration: underline;
  }

  &:active {
    color: $dark;
    text-decoration: underline;
  }

  &:focus {
    color: $dark;
    border: 3px solid $blue;
    border-radius: 4px;
  }
}

.data-integration-loader {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  border: 2px solid rgba(0, 0, 0, .125) !important;

  .item {
    flex: 0 0 auto;
    margin: 10px;

    p {
      text-align: center
    }
  }
}


table.data-integration-preview {
  tbody {
    tr {
      td {
        input {
          border: none;
          padding: 0;
          margin: 0;
        }

        input:focus {
          outline: none;
          border: 1px solid #DEE2E6;
          border-radius: 3px;
          background-color: #f8f9fa;
        }
      }
    }

  }

  tfoot {
    tr {
      background-color: $body-bg;

      td {
        vertical-align: top;
        padding-top: 0.875rem
      }
    }

  }
}

.file-format {
  display: flex;
  gap: .5rem;
  align-items: center;
  margin-bottom: 2rem;
}

.link-standard.no-focus {
  border: none;
}

.p-05 {
  padding: 0.5rem !important;
}

.p-30 {
  padding: 30px !important;
}

.link-inciting {
  color: $blue;
  font-weight: 500;

  &:hover {
    color: $blue-hover;
    text-decoration: underline;
  }

  &:active {
    color: $blue-hover;
    text-decoration: underline;
  }

  &:focus {
    color: $blue-hover;
    border: 3px solid $blue;
    border-radius: 4px;
  }
}

.offcanvas-body {
  .form-switch {
    .form-check-input {
      border-radius: 12px;
      background-color: $gray;
      border: 1px solid $gray-light;
      background-image: escape-svg($form-switch-checked-bg-image);
    }

    .form-check-input:checked {
      border-radius: 12px;
      background-color: $blue;
      border: 1px solid $gray-light;
    }

    .form-check-input[type="checkbox"]:focus {
      border-radius: 12px;
      border: 1px solid $gray-light;
      box-shadow: none;
    }

    &:focus {
      outline-color: $blue;
    }
  }

}

.no-wrap {
  white-space: nowrap;
}

.search-button-light {
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $dark !important;
  }
}

.form-select {
  margin-bottom: 0 !important;

  option:disabled {
    background-color: $background;
    font-style: italic;
  }
}

.form-control:focus, .form-select:focus {
  border-color: $blue;
  box-shadow: 0 0 4px #007CCBCC;
  color: $text-color;
}

.form-control {
  color: $text-color;
  font-weight: 500;
  border-radius: 8px;
}

.form-control::placeholder {
  color: $gray;
}

.form-control.error, .form-select.error {
  border-color: $red;
  &:focus {
    box-shadow: 0 0 4px #DC3545CC;
  }
}

.form-check-input[type="checkbox"] {
  border-color: $dark;
  border-radius: 2px;

  &:focus {
    border-radius: 3px;
    border-color: $blue;
    box-shadow: none;
  }
}

.follow-up {
  .card-body {
    padding: 30px !important;
    &.mobile {
      padding: 15px !important;
    }
  }

  .row {
    margin-bottom: 0 !important;
  }

  strong {
    white-space: break-spaces;
    font-size: 1.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
  }

  .log-item-detail div:first-child { /* item date */
    font-size: 1rem;
    color: $gray;
    margin-top: 0.5rem;
  }

  a {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    display: block;
  }

  p {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $dark-gray;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .log-item:not(:first-child) {
    .log-item-detail {
      margin-bottom: 2rem;
      min-height: 2rem;
    }
  }
}

.log-case-item-detail {
  margin-bottom: 2rem;
  min-height: 2rem;
}

.secondary.dropdown-toggle {
  border-color: $dark-gray;
  color: $dark-gray;
}

.dropdown-menu.show {
  width: auto;
  overflow: hidden;
  border: none;
  box-shadow: $--box-shadow;
  z-index: 1060;
}

.dropdown-item {
  color: $text-color;
  &.dropdown-item-mb {
    padding: 0.5rem 1rem;
  }
  a {
    display: inline-block;
    padding: 0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    background-color: $background;
  }

  &:active, &:focus {
    background-color: $background;
    text-decoration: none;
    color: $dark;
    border: none;
  }
}

.popover-header {
  margin-top: 0 !important;
}

.popover-w330 {
  max-width: 330px;
}

.popover-w350 {
  max-width: 350px;
}

.popover-w500 {
  max-width: 500px;
}

.popover-inducement {
  box-shadow: 0 1px 7px 2px rgba(73, 80, 87, 0.3);

  .popover-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .popover-message {
    font-size: $font-size-base;
  }

  .popover-date-picker {
    margin-bottom: 1.5rem;
  }

  .popover-date:focus {
    outline: none !important;
    border-color: $blue;
    box-shadow: 0 0 4px #007CCBCC;
  }

  .popover-date-invalid:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.3);

  }

  .popover-date {
    border-color: $input-border-color;
  }

  .popover-date-invalid {
    border-color: #dc3545 !important;
  }

  .popover-error {
    color: #dc3545;
  }

  .date-picker {
    width: 100%;
    height: 2.5rem;
    margin: auto;
    border: solid $input-border-color 1px;
    border-radius: 5px;
    font-size: $font-size-base;
    padding: 0 0.5rem;
  }
}

.col-main {
  @extend .mb-2;
  @extend .px-3;
}

.vh-60 {
  height: 60vh;
}


.menu-main {
  @extend .py-0;

  height: 72px;

  .container-fluid {
    height: 100%;
    @extend .px-0;

    .navbar-brand {
      margin: 0;
      padding: 0 1.5rem;
      height: 100%;
      @extend .d-flex;
      @extend .align-items-center;

      > a {
        border: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .container-search-icon {
      padding: 0.3rem 0.4rem;
      margin-right: 5vw;
      border-radius: 1.2rem;
      background-color: rgba(255, 255, 255, 0.2);
      font-size: 0.75rem;
    }


    .me-auto {
      height: 100%;

      > a {

        text-decoration: none;
        border-radius: 0;
        border: none;
        font-weight: normal;
        height: 100%;

        border-bottom: 4px solid transparent;
        color: $dark;
        @extend .pt-4;
        padding-left: 2.25rem;
        padding-right: 2.25rem;

        &.active-page {
          color: $blue;
          border-bottom: 4px solid $blue;
          font-weight: normal;
        }

        &.menu-link-tablet {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .no-margin {
            margin-right: 0 !important;
          }
        }

        svg {
          @extend .me-2
        }
      }
    }

    .user-menu {
      margin-right: 1.5rem;
      height: 100%;


      > .btn-link {
        color: $white;
        @extend .d-flex;
        @extend .align-items-center;

        :first-child {
          @extend .me-2;
        }
      }

      > .btn-link:hover {
        color: $background;
      }

      > .btn-link:focus {
        box-shadow: none;
      }

      .dropdown-toggle {
        &::after {
          content: none;
        }
      }

      .dropdown-menu {
        padding-bottom: 0;

        .dropdown-item-text {
          width: max-content;
          @extend .d-flex;
          @extend .align-items-center;
        }
      }

    }
  }
}

.dropdown-header {
  color: $text-color;
}

.dropdown-divider {
  margin-bottom: 0;
  margin-top: 0.25rem;
  border-top: 1px solid $gray;
}

.btn-group-flex {
  display: flex !important;
}

.table-filters-search-container {
  @extend .px-0;
  @extend .py-0;
  @extend .mb-3;
  @include media-breakpoint-down(sm) {
    .input-group {
      margin-bottom: 0.75rem;
    }
  }
}

.checkbox-selection-container {
  width: 40px;
  @extend .text-center
}

.button-with-icon {
  display: flex;
  gap: .5rem;
  align-items: center;
  font-weight: 400;
  margin-top: 1rem;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.loading {
  .table-responsive {
    padding-left: 1px;

    .error-mapping {
      tfoot tr {
        background-color: #f8d7da;
      }
    }
  }
}

.button-add {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hr-90 {
  margin: 2rem auto;
  width: 90%;
}

.language-select-container {
  position: fixed;
  top: 128px;
  z-index: 1000;
  box-shadow: $--box-shadow;
  border-radius: $--border-radius;

  .btn.btn-primary {
    border: none;
    border-radius: $--border-radius;
    height: 47px;
    background-color: $blue-light;
    color: $primary-blue !important;
    font-weight: 500;

    &:hover, &:focus {
      box-shadow: none;
    }
  }
}

.language-select-container-mb {
  text-align: end;
  .btn.btn-primary {
    border: none;
    border-radius: $--border-radius;
    height: 47px;
    background-color: $white;
    color: $dark-gray !important;
    font-weight: 500;
    box-shadow: $--box-shadow;
  }
}


.collapse-btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-copy {
  cursor: copy;
}

.font-weight-normal {
  font-weight: $font-weight-normal
}

.data-integration-page {
  select {

    border-radius: $--border-radius;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    color: $text-color;
    background-color: $blue-extra-light;
    border: none !important;
  }


  .select-data-integration {
    //.select-data-integration {
    //  border-radius: $--border-radius;
    //  padding: 0.875rem;
    //  color: $text-color;
    //  background-color: $blue-extra-light;
    //  border: none !important;
    //}


  }

  .drop-zone {
    height: 17rem;
    border-width: 3px;
    border-style: dashed;
    border-color: $gray;
    border-radius: $--border-radius;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    span {
      font-size: 0.875rem;
    }

    &.upload-in-progress {
      height: 20vh;
    }

    &.drag-active {
      border-width: 5px;
      border-style: dotted;
    }
  }
}


.drop-zone {
  height: 35vh;
  border-width: 2px;
  border-style: dashed;


  &.upload-in-progress {
    height: 20vh;
  }


  &.drag-active {
    border-width: 5px;
    border-style: dotted;
  }


  .card-body {
    @extend .d-flex;
    @extend .flex-column;
  }
}

.widget-event-tile {
  @extend .ms-5;
  @extend .py-5;
  line-height: 1;

  span {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;

    span {
      display: block;
      font-size: $font-size-base;
    }
  }
}


.detail-error-table {
  margin-top: 0.5rem !important;

  thead {
    display: none;
  }

  tfoot {
    display: none;
  }
}

.help-widget {
  .btn-close {
    width: .5rem;
    height: .5rem;
  }
}

.dropzone-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 6px;
  border-color: $gray;
  border-style: dashed;
  background-color: $background;
  color: $dark-gray;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone-error {
  border-color: $red;
}

.show-cell {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.show-more-address {
  margin-bottom: 0 !important;
  color: $dark-gray;
  font-style: italic;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.dsg-content {
  overflow: auto;

  .no-padding {
    padding: 0 !important;
  }
}


.dsg-container {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 100% !important;
  min-width: fit-content !important;
  border: none !important;

  .dsg-row-header {
    .dsg-cell {
      border-top: none !important;
    }
  }

  .dsg-cell-header.dsg-cell-gutter {
    box-shadow: none !important;
  }

  .dsg-container .dsg-row .dsg-cell-gutter {
    border: none !important;
  }

  .dsg-container .dsg-row-header .dsg-cell-gutter.dsg-cell-header {
    border: none !important;
  }

  .dsg-scrollable-view-container .dsg-scrollable-view {
    border: none !important;
  }

  .dsg-row {
    .dsg-cell:first-child {
      border: none !important;
    }

    .dsg-cell-gutter {
      border: none !important;
    }
  }

  .dsg-cell {
    border-left: none !important;
  }
}

#button-tooltip {
  margin-left: -20px !important;
}

.dsg-footer-container {
  width: 100% !important;

  .dsg-cell {
    border: none;

  }

  .error {
    background-color: #fddbdd !important;
  }
}

.dsg-cell-error {
  input {
    color: $red;
  }

  input::placeholder {
    color: $red;
  }
}

.preview-logo {
  position: relative;
  height: 160px;
  width: 100%;
  border-color: #eeeeee;
  border-width: 2px;
  border-style: dashed;
  display: flex;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    margin: 0 !important;
  }

  .action-preview-logo {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: rgba(5, 5, 5, 0.55);

    aside {
      margin-top: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: flex-end;

      span {
        margin-right: 1rem;
      }
    }
  }
}

.preview-logo > div:first-of-type {
  height: 100%;
  position: relative;
}

.preview-logo:hover {
  opacity: 0.55;

  .action-preview-logo {
    display: flex;
  }
}

.toc {
  .toc-title {
    @extend .text-title;
    padding: .5rem;
  }

  .nav-item.toc-lvl1 {
    @extend .h500;
    padding: 0.5rem 0;
  }

  .nav-item.toc-lvl2 .nav-link {
    @extend .h400;
    padding-left: 2rem;
  }

  .nav-item {
    margin-left: .5rem;
  }

  .nav-item.toc-lvl1:not(:first-child) {
    margin-top: .75rem;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: $blue;
    background-color: $background;
  }

  .nav-item > a:hover {
    text-decoration: none;
    background-color: $background;
  }

  .nav-item.active-category {
    color: $primary-blue;
  }

  .nav-link {
    line-height: 1.25rem;
    color: $text-color;
    cursor: pointer;
  }

}

.nav-link {
  cursor: pointer;

  &.active {
    cursor: default
  }
}

.reading-content {
  h1 {
    margin-bottom: 2rem !important;
  }

  .tab-content {
    padding: 1rem;
  }

  ul {
    padding-left: 0;

    li {
      margin-left: 1.5rem;
      list-style-type: disc;
    }
  }

  img {
    display: block;
    width: 90%;
    margin: auto auto 1rem;
  }

  p:last-child {
    margin-bottom: .5rem;
  }
}

.counter-type {
  cursor: pointer;
  font-weight: 500;

  &:hover {
    color: $dark-gray;
  }
}

.dashboard-page {
  .title {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 48px;
    color: $blue;
  }

  .event {
    background-image: url('../images/img_dashboard.svg');
    background-repeat: no-repeat;
    background-position: 10px -10px;
    height: 393px;
    width: 1120px;

    .widget-event {
      padding-left: .5rem;
      padding-right: .5rem;

      a {
        text-decoration: none;
      }

      .card {
        border-radius: 4px;

        .card-body {
          height: 120px;
          font-weight: 500;

          .counter {
            line-height: normal;

            span {
              display: flex;
              flex-direction: column;
              font-size: 3.5rem;
              text-align: right;

              span {
                font-size: 1.25rem;
              }
            }
          }
        }

        .card-footer {
          height: 40px;
          overflow: hidden;

          a {
            color: #ffffff;
            display: contents;
          }
        }
      }

      .card.dispute {
        color: $gray;

        .card-footer {
          background-color: $gray;
          color: #ffffff;
        }
      }

      .card.alerts {
        color: $gray;

        .card-footer {
          background-color: $gray;
          color: #ffffff;
        }
      }

      .card.reminder {
        color: $blue;

        .card-footer {
          background-color: $blue;
          color: #ffffff;
        }
      }
    }
  }
  .welcome-mb {
    font-size: 2rem;
    background-color: $white;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@include media-breakpoint-down(xl) {
  .mb-mobile {
    margin-bottom: 4.5rem !important;
  }
  .dashboard-page {
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 40px;
    }

    .event {
      background-image: none;
      height: auto;
      width: auto;
      margin-left: -12px;
      margin-right: -12px;
    }

    .widget-event {
      margin-bottom: .5rem;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .counter {
        margin-right: 1.5rem;
      }
    }
  }
}

.grid-errors {
  display: flex;
  gap: 1rem;

  .grid-error-lines {
    font-weight: 500;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}


.modal-dialog {
  .modal-title {
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: $--border-radius;
    background-color: $white;

    .modal-header {
      padding: 1rem 1.5rem 0.75rem;
    }

    .modal-body {
      padding: 1.5rem;
    }
  }
}

.modal-content {
  border: none;
  box-shadow: $--box-shadow;
}

.modal-confirmation {
  .form-button-group {
    margin-top: 1.25rem;
  }
}

.tiers-page {
  .menu-item {
    @extend .font-weight-normal;
    color: $dark-gray
  }
}

.actigrid-tab-filters {
  display: flex;
  position: relative;
  gap: 15px;
  font-size: 16px;
  border-bottom: solid $gray 1px;

  .tab {
    display: flex;
    gap: .5rem;
    align-items: center;
    font-weight: 500;
    color: $dark-gray;
    padding: 5px 14.5px;
    text-align: center;

    .red {
      color: $red;
    }

    .orange {
      color: $orange;
    }

    .yellow {
      color: $yellow;
    }
  }

  .tab:hover {
    cursor: pointer;
  }

  .tab-active {
    color: $primary-blue;
    //border-bottom: solid $primary-blue 3px;
    margin-bottom: -0.5px;
  }

  .slider {
    position: absolute;
    bottom: -1px;
    height: 4px;
    background-color: $primary-blue;
    transition: transform 0.3s ease, width 0.3s ease;
  }
}

/* width */
#actigrid-body::-webkit-scrollbar, .dsg-container::-webkit-scrollbar, .dsg-content::-webkit-scrollbar, .accepted-files-container::-webkit-scrollbar {
  width: 10px;

}

/* height */
.dsg-content::-webkit-scrollbar, .accepted-files-container::-webkit-scrollbar {
  height: 10px;
}

/* bg */
#actigrid-body::-webkit-scrollbar-track, .accepted-files-container::-webkit-scrollbar-track {
  background: rgba(189, 201, 209, 0.2);
  border-radius: 5px;

}

.dsg-container::-webkit-scrollbar-track, .accepted-files-container::-webkit-scrollbar-track {
  background: rgba(190, 202, 210, 0.2);
  border-radius: 5px;
}

.dsg-content .dsg-container::-webkit-scrollbar-track, .dsg-content::-webkit-scrollbar-track, .accepted-files-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 5px;
}

/* bar */
#actigrid-body::-webkit-scrollbar-thumb, .dsg-container::-webkit-scrollbar-thumb, .accepted-files-container::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
}

.dsg-content .dsg-container::-webkit-scrollbar-thumb, .dsg-content::-webkit-scrollbar-thumb:horizontal {
  background: #dddddd;
  border-radius: $--border-radius;
}

/* Handle on hover */
#actigrid-body::-webkit-scrollbar-thumb:hover, .dsg-container::-webkit-scrollbar-thumb:hover, .dsg-container::-webkit-scrollbar-thumb:hover, .accepted-files-container::-webkit-scrollbar-track:hover {
  background: $dark-gray;
  animation-duration: .2s;
}

.dsg-content .dsg-container::-webkit-scrollbar-thumb:hover, .dsg-content::-webkit-scrollbar-thumb:hover, .accepted-files-container::-webkit-scrollbar-track:hover {
  background: #dddddd;
}

.actigrid {
  padding: 0 0 10px 0;
  overflow: auto;

  &.simple {
    padding: 0 !important;
  }

  .form-check-input {
    width: 16px;
    height: 16px;
    border-radius: 5px !important;
    margin-top: 0 !important;
  }

  .actigrid-checkbox {
    padding-left: 1px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    font: normal normal normal 16px/19px $font-family-sans-serif;
    color: $text-color;
  }

  thead {
    position: relative;
    z-index: 1;
    transition-duration: .3s;

    tr {
      th {
        font-size: 14px;
      }
    }
  }

  thead.shadow-grid {
    box-shadow: 0 8px 8px -5px rgba(40, 49, 54, 0.16);
    transition-duration: .3s;
  }

  tfoot.shadow-grid {
    box-shadow: 0 -8px 8px -5px rgba(40, 49, 54, 0.16);
    transition-duration: .3s;
  }

  tfoot {
    position: relative;
    z-index: 1;
    transition-duration: .3s;
  }

  tbody {
    display: block;
    font-size: 16px !important;
    overflow-y: auto;
    max-height: 500px;

    tr {
      background-color: $white;
      height: 50px;
      transform: scale(1);
      box-shadow: $--box-shadow;
      -webkit-box-shadow: $--box-shadow;
      -moz-box-shadow: $--box-shadow;
      border-radius: $--border-radius;
      margin-bottom: 10px;
    }

    tr.selected-row {
      background-color: $primary-blue;

      // Overrides icon color if a row is selected
      .blue, .green, .yellow, .orange, .red, a, svg {
        color: $white;
      }

      td {
        background-color: $primary-blue;
        color: $white;

        &.link > span > a {
          color: $white !important;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          width: 100%;

          &:focus {
            border: none;
          }
        }
      }
    }
  }

  thead, tbody tr, tfoot {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 20px 10px;
  }

  td {
    min-width: 80px;
    padding: 2px 0 0 0;
    background-color: $white;
  }

  span {
    overflow: hidden;
  }

  .link > span > a {
    color: $primary-blue;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 100%;

    &:focus {
      border: none;
    }
  }

  .link-bold > span > a {
    font-weight: 600;
  }

  $--tr-padding: 2rem;

  tr td:first-child {
    border-top-left-radius: $--border-radius;
    border-bottom-left-radius: $--border-radius;
  }

  tr td:last-child {
    border-top-right-radius: $--border-radius;
    border-bottom-right-radius: $--border-radius;
  }

  .tr-no-data {
    background-color: rgb(248, 249, 250);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
  }

  .menu-action {
    display: flex;
    align-items: center;
  }

  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f8f9fa !important;
  }

  .table-striped > tbody > tr:nth-child(odd) {
    --bs-table-accent-bg: none;
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  .indeterminate-checkbox {
    margin: .7rem;
  }

  .text-end {
    text-align: end !important;
  }

  thead {
    .header-sort {
      cursor: pointer;
    }

    .sort-icon {
      margin-left: .25rem;
    }
  }

  .actigrid-pagination {
    @include actigrid-pagination-style;
  }
}

.has-scroll {
  thead, tbody {
    padding-right: 10px;
  }
}

.input-group {
  box-shadow: $--box-shadow;
  border-radius: $--border-radius;
  height: 47.5px;

  .input-search-icon {
    border: none;
    background-color: $white;
    color: $dark-gray;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 0.375rem 1rem !important;
  }

  .input-search {
    border: none;
    background-color: $white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  ::placeholder {
    color: $gray !important;
  }
}


//Actipay new Design

.card {
  overflow: hidden;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
  background-clip: padding-box !important;
  background-color: $white;
  margin-bottom: 0 !important;
  border: none !important;
  height: fit-content;
  color: $text-color;
}

.dropdown-menu {
  background-color: $white;
  color: $text-color;
}

.page-title {
  display: flex;
  flex-direction: row;
  height: 8rem;
  background-color: $blue-light;
  color: $primary-blue;
  align-items: center;
  margin-bottom: 0 !important;

  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2.8rem;
      margin-bottom: 0;
      font-weight: 600;
      margin-left: 8px;
    }
  }
}

.page-title.no-shadow, .card.no-shadow {
  box-shadow: none;
}

#collapse-action {
  border-top: 1px solid $gray-light !important;
  margin-right: -10px;
  margin-left: -10px;
}

.widget-premium {
  border-radius: 0 !important;
  background-color: $blue-extra-light;
  color: $text-color !important;

  .list-group-item {
    color: $text-color !important;
    background-color: $blue-extra-light;
  }
}

.widget-premium.bordered {
  border: solid 1px $blue !important;
  border-radius: $--border-radius !important;
}

.widget-action {
  position: absolute;
  z-index: 100;
  min-height: 190px;
  width: 100%;
  left: 0;
  //max-width: 285px;

  .card-footer {
    padding: 0 !important;
    background-color: white !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;


    span {
      color: $gray !important;
      letter-spacing: 5px
    }
  }

}

.file-format-data {
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .box {
    span {
      line-height: 12px !important;
      white-space: nowrap;
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul {
      padding: 0 !important;
      margin: 0 !important;
      font-size: 0.875rem !important;
      display: flex !important;
      flex-direction: row !important;

      li:first-child {
        list-style: none;
      }

      li {
        padding-right: 10px !important;
        margin-right: 14px;
      }
    }
  }

  .box--end {
    margin-left: auto;
  }
}

.actipay-navbar {
  background-color: rgba(255, 255, 255, 100);
}

//@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
//  .actipay-navbar {
//    background-color: rgba(255, 255, 255, .32);
//    -webkit-backdrop-filter: blur(50px) saturate(120%);
//    backdrop-filter: blur(50px) saturate(120%);
//  }
//}

.actipay-navbar {
  height: 64px;
  padding: 5px 0;
  box-shadow: $--box-shadow;
  position: relative;
  top: 0;
  z-index: 1055;
  font-size: 16px;

  &.mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-shadow: none;
    background-color: $white;
    height: 56px;
    z-index: 1050;

    .container-search-icon {
      color: $blue;
    }
  }

  .ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .nav-right {
      margin-top: -5px;
      margin-right: 24px;

      .dropdown-toggle::after {
        display: none;
      }
    }

    .navitem {
      padding: 16px 32px;
    }

    .link {
      display: flex;
      align-items: center;
      gap: .25rem;
      cursor: pointer;
      color: $text-color;
      font-weight: 500;

      .link-icon {
        margin-right: 10px;
      }
    }

    .link-active {
      color: $primary-blue;
    }

    .search-bar {
      padding: 5px 0;

      .input-group {
        box-shadow: none;
        border-radius: $--border-radius;

        .btn-secondary {
          height: 47.5px;
          border-top-right-radius: $--border-radius;
          border-bottom-right-radius: $--border-radius;
          border-left: none;
          border-color: $gray-light;
        }

        .input-group-text {
          border-color: $gray-light;
          border-top-left-radius: $--border-radius;
          border-bottom-left-radius: $--border-radius;
          background-color: $white;
          color: $dark-gray;
          padding: 0.375rem 1rem !important;
        }

        input {
          border-color: $gray-light;
          border-top-right-radius: $--border-radius;
          border-bottom-right-radius: $--border-radius;
          padding: .375rem .75rem .375rem .25rem;
        }

        input:focus {
          box-shadow: none;
        }

        ::placeholder {
          color: $gray !important;
        }
      }
    }
  }
}

.menu-border-bottom {
  position: absolute;
  bottom: -1px;
  height: 4px;
  background-color: $primary-blue;
  transition: transform 0.1s ease, width 0.1s ease;
  border-radius: 6px 6px 0 0;
}

.progress {
  background-color: $primary-blue;
  border-radius: 15px;
  height: 12px;

  .progress-bar {
    background-color: $secondary-blue;
  }
}

.data-integration-menu {
  width: 100%;
  margin-top: 2.5rem;

  ul {
    margin-bottom: 0 !important;
    border-bottom: 1px solid $gray !important;
  }

  .nav-tabs .nav-link.active {
    font-size: $h500-font-size;
    font-weight: bold;
    color: $blue;
    background: none;
    border: none;
    border-bottom: 3px solid $blue !important;
  }

  .nav-tabs .nav-link {
    font-weight: bold;
    color: $dark-gray;
    border: none;
  }

  .nav-tabs .nav-link:hover {

    border: none;
  }
}

.info-progress-container {
  margin-top: 1rem;
  color: $text-color;

  .info-progress {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }

  .dot-blue-light {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: $secondary-blue;
  }

  .dot-blue-primary {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: $primary-blue;
  }
}

.error-data-integration {
  font-size: 1rem !important;
  font-weight: 500;
  align-content: center;
  background: $red-light !important;
  border-radius: 4px !important;
  border: 0;
  border-left: 5px solid $red !important;
  flex-basis: content;
  flex-direction: column;
  display: inline-flex !important;
}

.card-whithout-padding {
  padding: 0 !important;

  .card-body {
    padding: 16px 0 !important;

    h5, p, span {
      padding: 0 16px !important;
    }
  }
}

.container {
  max-width: 2000px;
  padding: 0 4rem;
  @media (max-width: 768px) {
    padding: 0 .25rem;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0;
  }
}


.row {
  margin-bottom: 14px !important;
}

.tiers-page, .disputes-page, .data-integration-page, .account-page, .create-tiers-page, .alerts-page, .help-page, .mail-page, .cases-page, .import-doc-page, .create-case-page, .validate-delegation {
  &:not(.page-mb) {
    margin-top: 1.5rem;
  }

  .input-group {
    padding: 0;
  }
}

.validate-delegation {
  margin: 1.5rem auto 0 auto;

  @media screen and (min-width: 1350px) {
    width: 80%;

  }
  @media screen and (max-width: 1300px) {
    width: 100%;
  }

}

.m-0 {
  margin: 0 !important;
}

.w-16 {
  width: 16px;
}

.spinner {
  color: $primary-blue;
}

.spinner-icon {
  color: $blue-extra-light;
}

#dropdown-user-menu > svg {
  background-color: $gray-light;
  border-radius: 50px;
  color: $gray;
  width: 42px;
  height: 42px;
}

.missing-file-container {
  background-color: $orange-light;
  border-left: solid 5px $orange;
  border-radius: 4px;
  box-shadow: $--box-shadow;
  padding: 13px 20px;
  color: $orange;
  font-size: 14px;
  margin-top: 2rem;
  margin-left: 1.25rem;
}

.to-complete-tag {
  color: $orange;
  background-color: $orange-light;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 4px;
}

.reminders-progress-percentage {
  color: $primary;
  font-weight: 600;
}

.reminders-progress-subtitle {
  color: $primary;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 10px;
}

.card-widget {
  padding: 0 !important;

  .card-body {
    padding: 1rem 2rem 0 2rem !important;
    //margin-bottom: -2rem;
  }
}

.tooltip {
  z-index: 10000 !important;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-light > .tooltip-inner {
  background-color: $text-color;
  color: $white;
  border-radius: 10px;
  box-shadow: $--box-shadow;
  opacity: 1;
}

[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: $text-color;
}

[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: $text-color;
}

[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: $text-color;
}

[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: $text-color;
}

.fs-title {
  font-size: 45px;
  font-weight: 600;
  line-height: 1;
}

.text-surtitle {
  font-weight: 500;
  opacity: .5;
}

.initial-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
  color: $white;
  line-height: 1;
  background: linear-gradient(226deg, $primary-blue, #36A0EA);
  border-radius: 50%;
}

.menu-action-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  margin-bottom: .5rem;
  &:not(.disabled) {
    color: $primary-blue;
    cursor: pointer;
    .icon-item {
      color: $dark-gray
    }
  }
  &.disabled {
    color: $gray;
    cursor: default;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-anim {
  position: absolute;
  content: '';
  top: 2.5rem;
  left: calc(50% - 2.5rem);
  width: 5rem;
  height: 5rem;
  border: 5px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s;
  animation: spin 1s linear infinite;
}

.loader-success {
  background: $white;
  border-color: $green;
}

.loader-anim.loader-success {
  &::before {
    height: 7px;
    width: 2.25rem;
    position: absolute;
    top: 65%;
    left: 42%;
    background-color: $green;
    transform: rotate(-45deg);
    transform-origin: 0 50%;
    border-radius: 5px;
    animation: baseGrow 1s;
    content: '';
  }

  &::after {
    height: 7px;
    width: 1.5rem;
    position: absolute;
    top: 62%;
    left: 46%;
    background-color: $green;
    transform: rotate(-135deg);
    transform-origin: 0 50%;
    border-radius: 5px;
    animation: tipGrow 1s;
    content: '';
  }
}

.loader-anim.loader-success {
  animation: pop 1.2s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top: 5px solid $blue;
  }
  100% {
    transform: rotate(360deg);
    border-top: 5px solid $blue;

  }
}

@keyframes tipGrow {
  0% {
    width: 0;
    left: 0;
    top: 0;
  }
  25% {
    width: 0;
    left: 0;
    top: 0;
  }
  50% {
    top: 0;
    left: 0;
    width: 0;
  }
  75% {
    top: 0;
    left: 0;
    width: 1.2rem;
  }
  100% {
    top: 65%;
    left: 50%;
    width: 1.5rem;
  }
}

@keyframes baseGrow {
  0% {

    width: 0;
  }
  90% {
    width: 0;
  }
  100% {

    width: 2.5rem;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes leftIn {
  0% {
    left: 0;
    top: 0;
    width: 0;
  }
  100% {
    top: calc(50% - 0.25rem);
    left: calc(50% - 1.5rem);
    width: 3rem;
  }
}

@keyframes rightIn {
  0% {
    right: 0;
    top: 0;
    width: 0;
  }
  100% {
    top: calc(50% - 0.25rem);
    right: calc(50% - 1.5rem);
    width: 3rem;
  }
}

.result-chevron {
  cursor: pointer;
  color: $red;
}

.result-chevron.disabled {
  color: $red-light;
}

.unselectable {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */

  /* No support for these yet, use at own risk */
  user-select: none;
}

select:disabled {
  color: $dark-gray;
}

.font-size-4 {
  font-size: 4rem;
}

.font-size-18 {
  font-size: 18px;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tiers-info-hr {
  background-color: $gray-light;
  opacity: 1;
  width: 90%;
  margin: 1rem auto;
}

.unknownTiers {
  .carousel-control-prev {
    display: none;
  }

  .carousel-control-next {
    display: none;
  }

  .carousel-indicators-widget {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;

    button {
      background-color: darkgrey !important;
    }
  }

  .carousel-indicators-widget .active {
    opacity: 1 !important;
  }

  .carousel-indicators-widget [data-bs-target] {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #3498db;
    flex: 0 1 auto;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-color: transparent transparent transparent;
  }

  .carousel-indicators {
    display: none;

    button {
      background-color: darkgrey;
    }
  }

}

.widget-event-link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: .5rem;
  width: 100%;
}

.widget-event-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 26px 12px 12px;
  box-shadow: $--box-shadow;
  border-radius: $--border-radius;

  .link-content {
    display: flex;
    align-items: center;
    gap: .5rem;

    .value-text {
      display: flex;
      align-items: center;
      gap: .25rem;
      font-weight: 500;
      width: 3.5rem;
    }

    .label-text {
      font-weight: 500;
    }
  }
}

.welcome-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: 180px;
  overflow: hidden;
  margin-left: -3rem;
  margin-right: -3rem;
  margin-bottom: 2rem;
}

.card-action {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  background-color: $white;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
}

.card-action-content {
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}

.card-action-collapse {
  display: none;
  position: absolute;
  z-index: 100;
  height: auto;
}

.card-action-collapse.expanded {
  display: block;
}

.card-action.expanded .card-action-content {
  transform: translateY(100%);
}

.actigrid-mobile {
  background: transparent;
  margin-bottom: 50px;

  .actigrid-pagination {
    @include actigrid-pagination-style;
  }

  .rows-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .row-mobile {
      width: 100%;
      background-color: $white;
      border-radius: $--border-radius;
      padding: 12px 16px;
      box-shadow: $--box-shadow;
    }
  }
}

.collapsing {
  overflow: inherit;
}

.custom-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  .custom-card-body {
    flex: 1;

    ul {
      li {
        list-style-type: none;
        display: flex;

      }
    }
  }

  .custom-card-footer {
    background-color: white;
    border: none;

    button {
      width: 100%;
    }

  }
}


.welcome-title {
  display: flex;
  align-items: center;
  margin-left: 3rem;
  color: $blue;
  font-size: 45px;

  .name {
    font-weight: bold;
    margin-left: 8px;
    white-space: nowrap;
  }
}

.dashboard-widget-container {
  margin: 0 2rem;
}

.dashboard-widget-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

@keyframes mask {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(280px)
  }
}

#mask {
  animation: mask 975ms ease infinite;
}

.files-wrapper {
  background-color: $white;
  box-shadow: $--box-shadow;
  border-radius: $--border-radius;
  padding: .75rem 1rem;
  height: 133px;

  .accepted-files-container {
    height: 0;
    min-height: 100%;
    overflow-y: auto;
    padding-right: 1rem;

    .file-info {
      padding: .25rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.form-check-doc {
  display: flex;
  background-color: $background;
  border: solid 1px $gray-light;
  border-radius: $--border-radius;
  align-items: flex-start;
  padding: 1rem;
  gap: .5rem;
}

.collapsing {
  div {
    opacity: 0;
    transition: opacity .1s;
  }
}

.collapse.show:not(.mb) {
  div {
    opacity: 1;
    transition: opacity .2s;

  }
}

.dashboard--premium-widget {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
  text-align: center;
  background-color: $blue-extra-light;
  border: solid 1px $blue;
  border-radius: $--border-radius;

  .widget-subtitle {
    line-height: 1.1;
  }

  .widget-items-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    .widget-item {
      display: flex;
      gap: 10px;
    }

    .fade-0 {
      opacity: 1;
    }

    .fade-1 {
      opacity: .8;
    }

    .fade-2 {
      opacity: .6;
    }

    .fade-3 {
      opacity: .4;
    }
  }
}

.monitored-company--widget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
  cursor: pointer;

  .link-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .label-text {
    color: $blue;
    font-weight: 500;
  }
}

.stepper-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid $background;
  width: 100%;
  top: 17px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $background;
  width: 100%;
  top: 17px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: $background;
  margin-bottom: 4px;
  color: $dark-gray;
}

.stepper-item.completed .step-name {
  color: $blue;
}

.step-name {
  color: $dark-gray;
  text-align: center;
  font-size: 14px;
}

.step-counter {
  font-weight: 600;
  font-size: 17px;
  line-height: 1;
}

.stepper-item.active .step-counter {
  background-color: $white;
  border: solid 2px $blue;
  color: $blue;
}

.stepper-item.completed .step-counter {
  background-color: $blue;
  color: $white;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $blue;
  width: 100%;
  top: 17px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.onboarding-container {
  background-image: url("../images/background-onboarding.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.onboarding-title {
  font-size: 2.25rem;
  font-weight: bold;
  color: $blue;
}

.onboarding-form-container {
  padding: 0 4rem;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
}

.radio-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .radio-group {
    display: flex;
    align-items: center;
    gap: .25rem;
  }
}

.total-amount-widget {
  font-weight: 500;

  .total-amount-widget--title {
    font-size: 1.25rem;
    color: $gray;
  }

  .total-amount-widget--amount {
    font-size: 3rem;
    color: $blue;
  }

  .total-amount-widget--elements {
    font-size: 1.15rem;
    color: $blue;
  }
}

.case-status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: .5rem;
  border-radius: 8px;
  text-align: center;
}

.case-status.closed, .case-status.sent {
  background-color: $background;
}

.case-status.opened {
  background-color: $green-light;
}

.case-status.all {
  background-color: $blue-light;
}
.case-status.delegation {
  background-color: $background;
}
.case-status.formal-notice {
  background-color: $yellow-light;
}

.textarea-2-rows {
  overflow: hidden;
  color: $text-color;
  padding: .5rem .75rem;
  resize: none;
  width: 100%;
  border: solid 2px $gray-light;
  border-radius: $--border-radius;

  &:focus, &:focus-visible {
    transition: .2s;
    outline: none;
    border: solid 2px $blue;
  }
}

.textarea-2-rows::placeholder {
  color: $gray;
}

.button-send {
  border-radius: 50%;
  height: 40px !important;
  width: 40px;
}

.case-comment {
  background-color: $background;
  padding: 1rem;
  border-radius: 15px 15px 15px 0;

  .delete-comment {
    padding: 1rem;
    margin: -1rem;
    height: fit-content;

    &:hover {
      color: $red;
      transition: .2s;
    }
  }
}

.button__badge {
  background-color: $red;
  border-radius: 50%;
  padding: 4px 4px;
  position: absolute;
  top: 0;
  right: 0;
}

.confirm-case-container {
  width: fit-content;
  margin: auto;
  .confirm-case-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .5rem 1rem;
    background-color: white;
    border-radius: $--border-radius;
    box-shadow: $--box-shadow;
    cursor: pointer;

    input {
      width: .9rem;
      height: .9rem;
    }
  }
}

.rounded-button-danger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  color: $red;
  border: solid 1px $red;
  border-radius: $--border-radius;
  padding: .25rem .75rem;
  transition: .1s;

  &:hover {
    transition: .3s;
    color: $background !important;
    background-color: $red;
  }
}

.btn-link.dropdown-toggle {
  color: #007bff;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}

.btn-link.dropdown-toggle:hover {
  text-decoration: underline;
  color: #007bff;
}

.btn-link.dropdown-toggle:focus, .btn-link.dropdown-toggle:active {
  color: #007bff;
}

.vertical-separator {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-left: solid 1px $gray-light;
    height: calc(100% - 5rem);
  }
}
.modal.show {
  z-index: 1950;
}
.modal-backdrop {
  z-index: 1900;
}
.modal-backdrop-verified {
  z-index: 1900;
}

.modal-backdrop-verified.show {
  opacity: 0.4;
}

.choice.custom-card.active {
  background-color: $primary-blue;
  color: $white;

  .icon-formula {
    background-color: white;
    border: 4px solid $secondary-blue !important;
    color: $primary-blue
  }

  ul li {
    width: 100%;
    color: $white;

    .circle {

      svg {
        color: white !important;
      }
    }

    .dot {
      background-color: white;

      svg {
        color: green;
      }
    }
  }

  .btn.btn-secondary {
    background-color: $white !important;
    border-color: $primary-blue;
    color: $primary-blue !important;
  }
}

.choice.custom-card {
  color: $primary-blue;

  .icon-formula {
    height: 30px;
    width: 30px;
    background-color: $primary-blue;
    border: 4px solid $blue-light !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.3rem;
    float: left;
    margin-top: 4px;
    color: white
  }

  .intro {
    min-height: 105px;
  }

  ul li {
    width: 100%;
    color: gray;
    display: block;

    .dot {
      height: 16px;
      width: 16px;
      background-color: $gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.3rem;
      float: left;
      margin-top: 4px;
    }

    .circle {
      width: 15px;
      height: 15px;
      display: flex;
      margin-left: 5px;

      svg {
        color: $primary-blue !important;
      }
    }

    svg {
      color: white;
    }
  }
}

.tiers-page {
  .actigrid {
    td {
      span {
        overflow: visible
      }
    }
  }
}

.link-component {
  display: flex;

  a {
    //color: $primary-blue !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    display: inline-block;
  }

}

.choice-b2b-b2c {
  .toggle-group-active.btn.btn-secondary:active, .toggle-group-active.btn.btn-secondary {
    color: white !important;
    background-color: $primary-blue !important;;

    svg {
      color: white;
    }
  }
}

.ag-button-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #e3f7ff;
  color: #007ccb;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  transition: .2s ease-in-out;
  font-weight: 500;
  &:hover {
    box-shadow: $--box-shadow;
    transition: .2s ease-in-out;
  }
}

.actipay-nav-mobile {
  background-color: $white-translucid;
  backdrop-filter: blur(4px) saturate(1.2);
  height: $nav-bar-bottom-height;
  box-shadow: 0 0 10px #7E9AAC29;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1800;
}

.menu-select-month-mb {
  background-color: $white;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
  text-decoration: none !important;
  padding: 0 8px;
  .btn-link.dropdown-toggle {
    text-decoration: none;
    color: $dark-gray;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.user-menu-mb {
  .btn-link.dropdown-toggle {
    text-decoration: none;
    color: $dark-gray;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.vertical-line-center {
  background: linear-gradient($gray, $gray-light) no-repeat center/2px 100%;
  height: 65%;
  margin-top: 6px;
}

.page-mb {
  margin-bottom: $nav-bar-bottom-height;
  padding: 0 1rem;
}
.follow-up-mb-page {
  background-color: $white;
}

.page-title-mb {
  font-size: 1.5rem;
  text-align: center;
  color: $blue;
  padding: 8px 0;
  border-bottom: solid 2px $blue;
  margin-bottom: 2rem;
  font-weight: 500;
  position: sticky;
  background-color: $white-translucid;
  backdrop-filter: blur(4px) saturate(1.2);
  top: 0;
  z-index: 1600;
  display: flex;
  align-items: center;
}

.modal-mb .modal-content {
  z-index: 1900;
  border-radius: 0 !important;
  .modal-body {
    margin-bottom: $nav-bar-bottom-height;
  }
}

/* Timeline vertical list */
#timeline-container {
  overflow: auto;

  .inner-container {
    max-width: 100vw;
    margin: 0;
  }
}

.timeline {
  margin: 0;
  position: relative;
  width: 100%;
}
ul.timeline {
  padding-left: 0.5rem;
}

.timeline-item {
  text-align: center;
  //font-size: 1.4rem;
  padding-top: 1rem;
  border-left: 2px solid $gray-light;
  position: relative;
  list-style-type: none;
  --item-width: calc(100%);

  &::before {
    content: "";
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: $blue;
    border: solid 8px $white;
    position: absolute;
    left: -13px;
    top: 14px;
  }

  &:last-child {
    border-bottom: none;
  }
}

@media only screen and (max-width: 800px) {
  .timeline {
    margin: 0;
    position: relative;
    left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .timeline-item {
    padding-top: 1rem;
  }
}

/* end of timeline */

.number-case {
  font-size: 4rem;
  @media (max-width: 335px) {
    font-size: 3rem;
  }
}

.btn-filter-mb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $dark-gray;
  padding: 1.2rem;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
  transition-duration: .2s;
  &.open {
    background-color: $dark-gray;
    color: $white;
    transition-duration: .2s;
  }
}

.filter-mb-container {
  overflow: visible;
}

/* Chrome, Safari, Edge, Opera */
.input-no-arrows::-webkit-outer-spin-button,
.input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-no-arrows {
  -moz-appearance: textfield;
}

.input-amount-currency::after {
  content: '€';
  position: absolute;
  top: 32px;
  right: 10px;
  font-weight: 500;
  padding-left: 5px;
  background-color: $white;
}

@media (max-width: 950px) {
  select,
  textarea,
  .form-control,
  .form-select,
  input {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 390px) {
  .input-date-mb {
    width: calc(100vw / 2.5);
  }
}

.card-menu {
  .card-menu__item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: solid 1px $gray-light;
    font-weight: 500;
    &:last-child {
      border-bottom: none;
    }
  }
  .card-menu__item-container:active {
    background-color: $gray-light;
  }
}
.settings-form-validation {
  position: sticky;
  bottom: $nav-bar-bottom-height;
  background-color: $white-translucid;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  margin-top: 4rem;
  z-index: 2000;
}

.row-mobile {
  .accordion-button, .accordion-body {
    padding: 0;
  }
  &.accordion-item {
    margin-bottom: .5rem;
    border: none;
  }
  .accordion-collapse {
    margin-top: .5rem;
  }
}

.popover {
  border: none;
  border-radius: $--border-radius;
  box-shadow: $--box-shadow;
  background-color: $white;
  .btn {
    padding: .5rem;
  }
  &.popover-danger {
    border-top: solid 4px $red;
    .popover-title {
      color: $red
    }
  }
  &.popover-alert {
    border-top: solid 4px $orange;
    .popover-title {
      color: $orange
    }
  }
  &.popover-info {
    border-top: solid 4px $blue;
    .popover-title {
      color: $blue
    }
  }
  .popover-header {
    border: none;
    border-radius: $--border-radius;
    background-color: $white;
  }
  .popover-arrow {
    &::before {
      border: none;
      box-shadow: $--box-shadow;
    }
    &::after {
      border-bottom-color: $white;
    }
  }
}

.Toastify__toast.w-500 {
  width: 500px;
  margin-left: -90px;
}
.Toastify__toast.w-600 {
  width: 600px;
  margin-left: -108px;
}

.modal-w-50 {
  width: 50vw;
  max-width: 50vw;
}

.rs--option {
  padding: .75rem 1rem;
  font-size: 1rem;
  &:hover, &[aria-selected="true"] {
    background-color: $gray-light;
    cursor: pointer;
  }
}

.text-pretty {
  text-wrap: pretty;
}

article p {
  text-align: justify;
}

.smooth-display {
  animation: smoothDisplay .5s forwards;
}

.status-pill {
  color: $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__error {
    background-color: $red;
  }
  &__success {
    background-color: $green;
  }
  &__partial {
    color: $orange
  }
}

.auto-suggest {
  position: absolute;
  z-index: 1000;
  min-width: 500px;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #203F54;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  top: 5rem;
  .auto-suggest__item {
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      background-color: $background;
    }
  }
}
