@mixin actigrid-pagination-style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 8px 0;
  .chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    transition: 0.3s;
    border: none !important;
    color: $text-color !important;
    background-color: $white;
    border-radius: $--border-radius;
    box-shadow: $--box-shadow;
  }
  .chevron:hover, .chevron:focus {
    cursor: pointer;
    background-color: $gray;
  }
  .page-indicator {
    span {
      color: $text-color !important;
    }
  }
}
