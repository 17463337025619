/**
*TODO: move fonts to CDN, assets.intactiv.com, to public folder ?
*/

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-Bold.woff") format("woff"), url("../fonts/IntrumSans-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-BoldItalic.woff") format("woff"), url("../fonts/IntrumSans-BoldItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-Medium.woff") format("woff"), url("../fonts/IntrumSans-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-MediumItalic.woff") format("woff"), url("../fonts/IntrumSans-MediumItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-Regular.woff") format("woff"), url("../fonts/IntrumSans-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntrumSans';
    src: url("../fonts/IntrumSans-RegularItalic.woff") format("woff"), url("../fonts/IntrumSans-RegularItalic.otf") format("opentype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
