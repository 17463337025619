@import "variables";
$error-color: $red;
$partial-color: $orange;
$success-color: $green;
$regular-color: $blue;

.actispinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: 24px;
  width: 50px;
  height: 50px;

  &__path {
    stroke: $regular-color;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  .cross, .triangle, .exclamation-mark, .exclamation-dot {
    opacity: 0;
  }
  circle.circle {
    transition: all 0.5s linear;
  }
  .check {
    opacity: 0;
  }

  &--fail {
    animation: none;
    .actispinner__path {
      stroke: $error-color;
      stroke-linecap: round;
    }
    circle.circle {
      animation: fill 0.5s forwards;
    }
    .cross {
      opacity: 1;
      stroke-width: 3.5px;
      transform-origin: 50% 50%;
      stroke-dasharray: 29;
      stroke-dashoffset: 29;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

  }

  &--partial {
    animation: none;
    .actispinner__path {
      stroke: $partial-color;
      stroke-linecap: round;
    }
    circle.circle {
      animation: fill 0.5s forwards;
    }
    .triangle {
      opacity: 1;
      stroke-width: 3px;
      transform-origin: 50% 50%;
      stroke-dasharray: 29;
      stroke-dashoffset: 29;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    .exclamation-mark {
      opacity: 1;
      stroke-width: 1.5px;
      transform-origin: 50% 50%;
      stroke-dasharray: 29;
      stroke-dashoffset: 29;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
    .exclamation-dot {
      transform-origin: 50% 50%;
      stroke-dasharray: 29;
      stroke-dashoffset: 29;
      animation: dot 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      fill: $partial-color;
    }
  }

  &--success {
    height:100%;
    animation: none;
    .actispinner__path {
      stroke: $success-color;
      stroke-linecap: round;
    }
    circle.circle {
      animation: fill 0.5s forwards;
    }
    .check {
      stroke-width: 0;
      fill: $success-color;;
      transform-origin: 50% 50%;
      stroke-dasharray: 20;
      stroke-dashoffset: 29;
      opacity: 0;
      animation: stroke2 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @keyframes stroke2 {
    0% {
      transform: scale(4);
      opacity: 0.2;
    }
    50% {
      transform: scale(2);
      opacity: 0.5;
    }
    100% {
      stroke-dashoffset: 0;
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dot {
    from {
      opacity: 0;
      stroke-width: 0;
    }
    to {
      opacity: 1;
      stroke-width: 1;
    }
  }
  @keyframes fill {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 123, 150;
      stroke-dashoffset: 0;
    }
  }
}
