@import 'variables.scss';
.accordion {
  .accordion-item {
    border: none;
    box-shadow: $--box-shadow;
  }
}
.list-group-item {
  background-color: $white;
}
