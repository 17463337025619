@import "fonts.css";
@import "variables";
@import "functions";
@import "mixins";

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.skeleton-loading {
  &.progress {
    background: $gray-light;
    height: 1rem;
    p {
      background: $blue-light;
      background: linear-gradient(110deg, $blue 8%, #61c2ff 18%, $blue 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      transition: .5s width cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }
  h2,
  p {
    background: $gray-light;
    background: linear-gradient(110deg, $gray-light 8%, $background 18%, $gray-light 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  p, .s-1 {
    height: 1rem;
  }

  h2, .s-2 {
    height: 2rem;
  }

  .s-3 {
    height: 3rem
  }

}