*, *::before, *::after {
  box-sizing: border-box;
}
 h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}
p, span {
  text-wrap: pretty;
}
html {
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 950px) and (max-width: 1700px) {
     font-size: calc(16px + (100vw - 1600px) * .01);
  }
  font-size: 16px;
}
body {
  margin: 0;
  min-height: 100dvh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #32607D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  /* Overrides Toastify variables */
  --toastify-color-success: #5CE882;
  --toastify-color-info: #007CCB;
  --toastify-color-warning: #FFCE48;
  --toastify-color-error: #FF5364;
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  .Toastify__toast {
    border-radius: 15px;
  }
}
